
import { defineComponent } from "vue";
import MainLayout from "@/layouts/MainLayout.vue";

export default defineComponent({
  name: "french-way",
  components: { MainLayout },
  setup() {
    return {
      MainLayout,
    };
  },
});
