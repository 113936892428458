import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_historicHeader = _resolveComponent("historicHeader")!
  const _component_pastFooter = _resolveComponent("pastFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_historicHeader),
    _renderSlot(_ctx.$slots, "default"),
    _createVNode(_component_pastFooter)
  ], 64))
}