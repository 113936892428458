import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_headerComp = _resolveComponent("headerComp")!
  const _component_footerComp = _resolveComponent("footerComp")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_headerComp),
    _createElementVNode("main", null, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createVNode(_component_footerComp)
  ], 64))
}