
import { defineComponent } from "vue";
import useStore from "@/store";
import titleComp from "@/common/titleComp.vue";

export default defineComponent({
  name: "past-nav",
  components: { titleComp },
  setup() {
    const store = useStore();
    return {
      store,
      titleComp,
    };
  },
});
