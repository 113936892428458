
import { defineComponent } from "vue";
import historicalNav from "@/modules/header/historicalNav.vue";
import donateComp from "@/common/donateComp.vue";
import useStore from "@/store";

export default defineComponent({
  name: "header-comp",
  components: { historicalNav, donateComp },
  setup() {
    const store = useStore();

    return {
      store,
      historicalNav,
      donateComp,
    };
  },
});
