import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lightButton = _resolveComponent("lightButton")!
  const _component_navMenu = _resolveComponent("navMenu")!
  const _component_donateComp = _resolveComponent("donateComp")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["inner flex justify-between", _ctx.store.dark ? 'bg-zinc-900/50' : 'bg-zinc-100/50'])
    }, [
      _createVNode(_component_lightButton, { class: "ml-5" }),
      _createVNode(_component_navMenu),
      _createVNode(_component_donateComp)
    ], 2)
  ]))
}