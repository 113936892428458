
import { defineComponent } from "vue";

export default defineComponent({
  name: "past-ways",
  setup() {
    return {
      manuscrito: require("@/assets/img/manuscrito.png"),
    };
  },
});
