import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_citiesComp = _resolveComponent("citiesComp")!
  const _component_nightCities = _resolveComponent("nightCities")!
  const _component_MainLayout = _resolveComponent("MainLayout")!

  return (_openBlock(), _createBlock(_component_MainLayout, null, {
    default: _withCtx(() => [
      (!_ctx.store.dark)
        ? (_openBlock(), _createBlock(_component_citiesComp, { key: 0 }))
        : (_openBlock(), _createBlock(_component_nightCities, { key: 1 }))
    ]),
    _: 1
  }))
}