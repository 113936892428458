import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_socialHeader = _resolveComponent("socialHeader")!
  const _component_socialFooter = _resolveComponent("socialFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_socialHeader),
    _renderSlot(_ctx.$slots, "default"),
    _createVNode(_component_socialFooter)
  ], 64))
}