
import { defineComponent } from "vue";
import useStore from "@/store";

export default defineComponent({
  name: "donate-link",
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
});
