
import { defineComponent } from "vue";
import socialHeader from "@/components/socialHeader.vue";
import socialFooter from "@/components/pastFooter.vue";

export default defineComponent({
  name: "social-layout",
  components: { socialHeader, socialFooter },
  setup() {
    return {
      socialHeader,
      socialFooter,
    };
  },
});
