
import { defineComponent, ref, Ref, onMounted } from "vue";
import * as THREE from "three";
import gsap from "gsap";
import imagesLoaded from "imagesloaded";
import { DisplacementSliderOptions, SlideCities } from "@/interfaces";
import useStore from "@/store";
import { slidesData, vertexShader, fragShader } from "@/constants";

export default defineComponent({
  setup() {
    const store = useStore();
    const slideTitleRef = ref();
    const slideStatusRef = ref();
    const paginationRef = ref();
    const imagesRef = ref<Ref<HTMLImageElement | null>[]>([]);
    let activeSlideId = ref<number>(0);
    const slides = ref<SlideCities[]>(slidesData);
    onMounted(() => {
      initializeSlider();
    });

    function initializeSlider() {
      imagesLoaded(imagesRef.value, () => {
        const el = document.getElementById("slider");
        if (el) {
          const imgs = Array.from(el.querySelectorAll("img"));
          displacementSlider({
            parent: el,
            images: imgs,
          });
        }
      });
    }
    const displacementSlider = function (opts: DisplacementSliderOptions) {
      let isAnimating = false;
      let vertex = vertexShader;
      let fragment = fragShader;
      let images: HTMLImageElement[] = opts.images,
        image: THREE.Texture,
        sliderImages: THREE.Texture[] = [];
      let canvasWidth = images[0].clientWidth;
      let canvasHeight = images[0].clientHeight;
      let parent = opts.parent;
      let renderWidth = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      );
      let renderHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      );
      let renderW: number, renderH: number;

      if (renderWidth > canvasWidth) {
        renderW = renderWidth;
      } else {
        renderW = canvasWidth;
      }

      renderH = canvasHeight;

      let RENDERER = new THREE.WebGLRenderer({
        antialias: false,
      });
      RENDERER.setPixelRatio(window.devicePixelRatio);
      RENDERER.setClearColor(0x23272a, 1.0);
      RENDERER.setSize(renderW, renderH);
      parent.appendChild(RENDERER.domElement);

      let loader = new THREE.TextureLoader();
      loader.crossOrigin = "anonymous";

      images.forEach((img: HTMLImageElement) => {
        image = loader.load(img.getAttribute("src") + "?v=" + Date.now());
        image.magFilter = image.minFilter = THREE.LinearFilter;
        image.anisotropy = RENDERER.capabilities.getMaxAnisotropy();
        sliderImages.push(image);
      });
      let SCENE = new THREE.Scene();
      SCENE.background = new THREE.Color(0x23272a);
      let CAMERA = new THREE.OrthographicCamera(
        renderWidth / -2,
        renderWidth / 2,
        renderHeight / 2,
        renderHeight / -2,
        1,
        1000
      );

      CAMERA.position.z = 1;

      let mat = new THREE.ShaderMaterial({
        uniforms: {
          dispFactor: {
            value: 0.0,
          },
          currentImage: { value: sliderImages[0] },
          nextImage: { value: sliderImages[1] },
        },
        vertexShader: vertex,
        fragmentShader: fragment,
        transparent: true,
        opacity: 1.0,
      });
      function changeSlide(slideId: number) {
        if (mat && mat.uniforms) {
          mat.uniforms.nextImage.value = sliderImages[slideId];

          gsap.to(mat.uniforms.dispFactor, {
            duration: 1,
            value: 1,
            ease: "Expo.easeInOut",
            onUpdate: function () {
              if (mat.uniforms.dispFactor.value >= 1) {
                mat.uniforms.currentImage.value = sliderImages[slideId];
                mat.uniforms.dispFactor.value = 0.0;

                isAnimating = false;
              }
            },
          });

          const slideTitleEl = slideTitleRef.value;
          const slideStatusEl = slideStatusRef.value;
          const nextSlideTitle = document.querySelectorAll(
            `[data-slide-title="${slideId}"]`
          )[0].innerHTML;
          const nextSlideStatus = document.querySelectorAll(
            `[data-slide-status="${slideId}"]`
          )[0].innerHTML;

          gsap.fromTo(
            slideTitleEl,
            {
              duration: 0.5,
              autoAlpha: 1,
              y: 0,
            },
            {
              autoAlpha: 0,
              y: 20,
              ease: "Expo.easeIn",
              onComplete: function () {
                if (slideTitleEl) {
                  slideTitleEl.innerHTML = nextSlideTitle;

                  gsap.to(slideTitleEl, {
                    duration: 0.5,
                    autoAlpha: 1,
                    y: 0,
                  });
                }
              },
            }
          );

          gsap.fromTo(
            slideStatusEl,
            {
              duration: 0.5,
              autoAlpha: 1,
              y: 0,
            },
            {
              autoAlpha: 0,
              y: 20,
              ease: "Expo.easeIn",
              onComplete: function () {
                if (slideStatusEl) {
                  slideStatusEl.innerHTML = nextSlideStatus;

                  gsap.to(slideStatusEl, {
                    duration: 0.5,
                    autoAlpha: 1,
                    y: 0,
                    delay: 0.1,
                  });
                }
              },
            }
          );
        }
      }

      let geometry = new THREE.PlaneGeometry(
        parent.offsetWidth,
        parent.offsetHeight,
        1
      );
      let object = new THREE.Mesh(geometry, mat);
      object.position.set(0, 0, 0);
      SCENE.add(object);
      const addEvents = function () {
        const paginationElement = paginationRef.value;

        if (paginationElement !== null) {
          const pagButtons: HTMLButtonElement[] = Array.from(
            paginationElement.querySelectorAll("button")
          );
          for (let i = 0; i < pagButtons.length; i++) {
            pagButtons[i].addEventListener("click", function () {
              if (!isAnimating) {
                isAnimating = true;

                paginationElement.querySelectorAll(".active")[0].className = "";
                this.className = "active";

                const slideIdString = this.dataset.slide;

                if (slideIdString !== undefined) {
                  const slideId = parseInt(slideIdString, 10);
                  activeSlideId.value = slideId;
                  changeSlide(slideId);
                }
              }
            });
          }
        }
      };
      addEvents();
      window.addEventListener("resize", function () {
        RENDERER.setSize(renderW, renderH);
        onWindowResize();
      });

      function onWindowResize() {
        CAMERA.updateProjectionMatrix();
        RENDERER.setSize(window.innerWidth, window.innerHeight);
      }

      let animate = function () {
        requestAnimationFrame(animate);

        RENDERER.render(SCENE, CAMERA);
      };
      animate();
    };
    return {
      slides,
      store,
      activeSlideId,
      slideTitleRef,
      slideStatusRef,
      paginationRef,
    };
  },
});
