
import { defineComponent } from "vue";
import navMenu from "@/modules/header/navMenu.vue";
import donateComp from "@/common/donateComp.vue";
import useStore from "@/store";
import lightButton from "@/common/lightButton.vue";

export default defineComponent({
  name: "header-comp",
  components: { navMenu, donateComp, lightButton },
  setup() {
    const store = useStore();

    return {
      store,
      navMenu,
      lightButton,
      donateComp,
    };
  },
});
